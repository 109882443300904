export function getQueryParameter(key: string): boolean | string | null {
	const url = window.location.href.split("#")[0];
	const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)");
	const results = regex.exec(url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return true;
	}
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}
