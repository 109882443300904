import { CommonValue, LocalStorageKeys } from "../enumerations/utils";
import { Listeners } from "../enumerations/web";
import { getQueryParameter } from "../utils/query";
import { getLocalStorage, setLocalStorage } from "../utils/storage";

const TUTORIAL_PAGE_COUNT = 4;
const TUTORIAL_SCROLL_SNAP_DISTANCE = 10;
const TUTORIAL_SCROLL_WAIT_DELAY = 100;

class BoardTutorial {
	private tutorialWrapper?: HTMLDivElement;
	private closeButton?: HTMLDivElement;
	private skipButton?: HTMLDivElement;
	private nextButton?: HTMLDivElement;
	private contentContainer?: HTMLDivElement;
	private framesContainer?: HTMLDivElement;
	private dotContainer?: HTMLDivElement;
	private pageContents: HTMLDivElement[] = [];
	private pageDots: HTMLDivElement[] = [];
	private slideWidth = 516;

	private currentPage = 1;
	private lastScrollPos = -1;

	public async init() {
		this.tutorialWrapper = document.querySelector("#tutorial-wrapper") as HTMLDivElement;
		this.closeButton = document.querySelector("#tutorial-close-button") as HTMLDivElement;
		this.skipButton = document.querySelector("#tutorial-skip-button") as HTMLDivElement;
		this.nextButton = document.querySelector("#tutorial-next-button") as HTMLDivElement;
		this.contentContainer = document.querySelector(".tutorial-content-container") as HTMLDivElement;
		this.dotContainer = document.querySelector(".tutorial-progress-container") as HTMLDivElement;
		this.framesContainer = document.querySelector(".tutorial-frames") as HTMLDivElement;
		this.pageContents = Array.from(document.querySelectorAll(".tutorial-progress-content")) as HTMLDivElement[];
		this.pageDots = Array.from(document.querySelectorAll(".tutorial-progress-dot-container")) as HTMLDivElement[];

		this.calculateSlideWidth(true);
		this.hookEvents();

		// Determine whether the tutorial should be shown.
		if (getQueryParameter("showTutorial") || getLocalStorage(LocalStorageKeys.TUTORIAL_SEEN, "false") === "false") {
			this.show();
		}
	}

	public show() {
		if (this.tutorialWrapper) {
			this.tutorialWrapper.classList.add("owFadeIn");
			this.tutorialWrapper.classList.remove("owFadeOut");
			this.tutorialWrapper.classList.remove("owHidden");
		}
	}

	public hide() {
		if (this.tutorialWrapper) {
			this.tutorialWrapper.classList.remove("owFadeIn");
			this.tutorialWrapper.classList.add("owFadeOut");
			setTimeout(() => {
				if (this.tutorialWrapper) {
					this.tutorialWrapper.classList.add("owHidden");
				}
			}, 500);
			setLocalStorage(LocalStorageKeys.TUTORIAL_SEEN, "true");
		}
	}

	private hookEvents() {
		if (this.closeButton) {
			this.closeButton.addEventListener(Listeners.CLICK_EVENT, this.onSkip.bind(this));
		}

		if (this.skipButton) {
			this.skipButton.addEventListener(Listeners.CLICK_EVENT, this.onSkip.bind(this));
		}

		if (this.nextButton) {
			this.nextButton.addEventListener(Listeners.CLICK_EVENT, this.onNext.bind(this));
		}

		if (this.contentContainer) {
			this.contentContainer.addEventListener(Listeners.TOUCH_END_EVENT, this.onPageScroll.bind(this));
			this.contentContainer.addEventListener(Listeners.MOUSE_UP_EVENT, this.onPageScroll.bind(this));
		}

		window.addEventListener(Listeners.RESIZE_EVENT, this.onResize.bind(this));

		this.pageDots.forEach((pageDot: HTMLDivElement) => {
			pageDot.addEventListener(Listeners.CLICK_EVENT, (event) => {
				const match = pageDot.id.match(/[\d]+/i);
				if (match) {
					const pageIndex = parseInt(match[0], CommonValue.PARSE_RADIX);
					this.setPage(pageIndex);
				}
			});
		});
	}

	private onResize() {
		if (this.contentContainer) {
			const scroll = this.getScrollForPage(this.currentPage);
			const left = scroll - this.contentContainer.scrollLeft;

			this.contentContainer.scrollBy({
				left,
				behavior: "smooth"
			});
		}
		this.calculateSlideWidth();
	}

	private onSkip() {
		this.hide();
	}

	private onNext() {
		if (this.currentPage + 1 > TUTORIAL_PAGE_COUNT) {
			this.onSkip();
		} else {
			this.setPage(this.currentPage + 1);
		}
	}

	private async onPageScroll() {
		await this.waitForScroll();

		if (this.contentContainer) {
			let closestDistance = 9999999999;
			let closestPage = -1;
			const scroll = this.contentContainer.scrollLeft;

			for (let i = 1; i <= TUTORIAL_PAGE_COUNT; ++i) {
				const pageScroll = this.getScrollForPage(i);
				const dist = Math.abs(scroll - pageScroll);
				if (dist < closestDistance) {
					closestDistance = dist;
					closestPage = i;
				}
			}

			if (closestPage > -1) {
				this.setPage(closestPage);
			}
		}
	}

	private async waitForScroll() {
		if (this.contentContainer) {
			const scroll = this.contentContainer.scrollLeft;

			if (this.lastScrollPos === -1) {
				this.lastScrollPos = scroll;
			}
		}

		do {
			await new Promise<void>((resolve, reject) => {
				setTimeout(() => {
					if (this.contentContainer) {
						const scroll = this.contentContainer.scrollLeft;
						if (Math.abs(this.lastScrollPos - scroll) <= TUTORIAL_SCROLL_SNAP_DISTANCE) {
							this.lastScrollPos = -1;
						} else {
							this.lastScrollPos = scroll;
						}
					}
					resolve();
				}, TUTORIAL_SCROLL_WAIT_DELAY);
			});
		} while (this.lastScrollPos > -1);
	}

	private setPage(pageIndex: number) {
		this.currentPage = Math.max(1, Math.min(TUTORIAL_PAGE_COUNT, pageIndex));

		if (this.dotContainer) {
			const pageArray = Array.from({ length: TUTORIAL_PAGE_COUNT }, (v, i) => i);
			const pageNames = pageArray.map((index) => {
				return `page-${index + 1}`;
			});

			this.dotContainer.classList.remove(...pageNames);
			this.dotContainer.classList.add(`page-${this.currentPage}`);
		}

		this.scrollFrame();
	}

	private getScrollForPage(page: number): number {
		if (this.contentContainer) {
			const scroll = this.contentContainer.scrollLeft;
			const contentRect = this.contentContainer.getBoundingClientRect();
			const pageRect = this.pageContents[page - 1].getBoundingClientRect();
			return pageRect.left + scroll - contentRect.left;
		}
		return 0;
	}

	private scrollFrame(withTransition = true) {
		if (this.framesContainer) {
			const scrollPx = -(this.currentPage - 1) * this.slideWidth;
			if (withTransition) {
				this.framesContainer.style.transition = `transform 500ms ease`;
				setTimeout(() => {
					if (this.framesContainer) {
						this.framesContainer.style.transition = ``;
					}
				}, 500);
			}
			this.framesContainer.style.transform = `translate3d(${scrollPx}px, 0px, 0px)`;
		}
	}

	private calculateSlideWidth(initial = false) {
		const width = window.innerWidth;
		const newWidth = width;
		if (this.slideWidth !== newWidth || initial) {
			this.slideWidth = newWidth;
			this.pageContents.forEach((page) => {
				page.style.width = `${newWidth}px`;
			});
			if (this.framesContainer) {
				this.framesContainer.style.width = `${TUTORIAL_PAGE_COUNT * newWidth}px`;
			}
			this.scrollFrame(false);
		}
	}
}

(window as any).TUTORIAL = new BoardTutorial();

declare const TUTORIAL: BoardTutorial;

window.addEventListener(Listeners.LOAD_EVENT, () => {
	TUTORIAL.init();
});
