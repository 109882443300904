export enum CommonValue {
	DEFAULT_REQUEST_LIMIT = 15,
	INFINITY = 100000,
	PARSE_RADIX = 10
}

export enum LocalStorageKeys {
	TUTORIAL_SEEN = "tutorialSeen",
	GUEST_BOARD_ID = "guestBoardId",
	GUEST_CART = "guestCart"
}

export const DEFAULT_BRAND = "Pottery Barn Kids";
